<template>
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header">
            <b-col cols="8">
              <h2 class="mb-0">
                <i class="fas fa-user-lock"></i> ข้อมูลสินค้า
              </h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body">
          <div class="card custom-card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-2 mb-2">รหัสสั่งซื้อ :</div>
                <div class="col-sm-10">
                  {{ order_detail.code || "" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="card custom-card">
            <div class="card-body">
              <div class="table-responsive padding_top_20">
                <table class="table align-middle table-bordered">
                  <thead>
                    <tr class="text-center">
                      <th>รหัสสินค้า</th>
                      <th>ชื่อสินค้า</th>
                      <th>ราคา</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="data in items"
                      :key="`list_mapping_product_preview_${data.id}`"
                    >
                      <td class="text-center">{{ data.product_code }}</td>
                      <td class="text-center">{{ data.product_name }}</td>
                      <td class="text-right">
                        {{ data.cost | (number - decimal - format) }}
                      </td>
                    </tr>
                    <tr v-if="items.length === 0" class="text-center">
                      <td colspan="3">ไม่มีข้อมูล</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="float-right pt-5 d-flex">
            <span
              ><router-link :to="'/delivery'" class="nav-link text-underline">
                กลับ</router-link
              ></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import DateUtils from "../../../util/dateUtils";

export default {
  name: "delivery-view-detail-page",
  data() {
    return {
      permission: {
        appSlug: "delivery",
        actionData: {},
      },
      id: "",
      order_detail: "",
      items: [],
    };
  },
  methods: {
    async getItems() {
      const result = await this.HttpServices.getData(
        `/ab_delivery/getOrderItems/${this.id}`
      );
      // console.log("result: ", result);
      if (result && result.status.code == "200") {
        this.items = result.data;
      } else {
        this.AlertUtils.alertCallback(
          "warning",
          `ไม่พบข้อมูลการสั่งซื้อ`,
          () => {
            this.$router.push("/delivery");
          }
        );
      }
    },
  },
  async mounted() {
    await this.PermissionServices.viewPermission(
      this.permission.appSlug,
      this.$router
    );
    this.permission.actionData = await this.PermissionServices.getPermission(
      this.permission.appSlug
    );
    this.order_detail = await this.SessionStorageUtils.getSession(
      "page-delivery"
    );
    this.order_detail = this.order_detail ? JSON.parse(this.order_detail) : {}
    this.id = this.order_detail.order_id || JSON.parse(this.order_detail).order_id;
    this.getItems();
  },
};
</script>
